<template>
  <v-snackbar
    v-model="show"
    :color="color"
    timeout="5000"
  >
    <div
      v-for="(message, index) in content"
      :key="index"
      class="subtitle-2"
    >
      {{ message }}
    </div>
  </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      timeout: null
    }
  },
  computed: {
    ...mapState('snackbar', [
      'isNotified',
      'color',
      'timeout',
      'content'
    ]),

    show: {
      get () {
        return this.isNotified
      },
      set () {
        if (!this.isNotified) {
          clearTimeout(this.timeout)
          return
        }
        this.timeout = setTimeout(this.handleSnackbarClear, 500)
      }
    }
  },

  methods: {
    ...mapActions('snackbar', [
      'clearSnackbar'
    ]),
    handleSnackbarClear() {
      this.clearSnackbar()
      clearTimeout(this.timeout)
    }
  }
}
</script>
